/* src/components/MainContent.css */
.main-content {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;

  .promotional-section {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .promotional-section .text {
    flex: 1;
  }

  .promotional-section h2 {
    font-size: 2rem;
    font-weight: bold;
  }

  .promotional-section p {
    font-size: 1rem;
  }

  .promotional-section button {
    background: black;
    color: white;
    padding: 10px 20px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
  }

  .promotional-section img {
    max-width: 50%;
    height: auto;
  }

  .pricing-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 40px;
  }

  .pricing-card {
    background: white;
    border: 1px solid black;
    padding: 16px 8px;
    display: flex;
  }

  .pricing-card {
    .image {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px 0 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    .size {
      font-size: 24px;
      font-weight: bold;
    }
    .price {
      font-size: 35px;
      font-weight: bold;
    }
    .freq {
      font-size: 18px;
      margin-bottom: 16px;
    }
    .detail1, .detail2 {
      font-size: 14px;
    }
  }

  .pricing-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .pricing-card p {
    font-size: 1rem;
  }

  .notes {
    grid-column: span 2;
    min-height: 130px;
    ul {
      padding-inline-start: 20px;
    }
  }
}

@media (max-width: 768px) {
  .promotional-section {
    flex-direction: column;
  }
  .promotional-section img {
    max-width: 100%;
  }
  .pricing-section {
    grid-template-columns: 1fr;
  }
  .notes {
    grid-column: span 1;
  }
}