.MobileLogisticsLanding {
  font-family: charter, serif;
  text-align: left;
  .banner-container {
    position: relative;
    display: flex;
    justify-content: center;
    .banner1 {
      width: 100%;
      height: auto;
    }
    .banner2 {
      width: 80vw;
      height: auto;
      margin-top: 32px;
      margin-bottom: 10px;
    }
    .yellow-logo {
      position: absolute;
      top: 10px;
      left: 16px;
    }
  }
  .paragraph {
    padding: 16px 10px;
    margin-bottom: 8px;
    .top-title {
      font-size: 24px;
      font-weight: bold;
    }
    .main-title {
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .detail {
      font-size: 14px;
      .price {
        font-size: 24px;
      }
    }
  }
  .book-now {
    display: flex;
    justify-content: end;
    .button {
      background: black;
      color: white;
      padding: 8px 24px 8px 8px;
    }

  }
  .boxes-wrapper {
    padding: 52px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    .BoxDetail {
      border: 1px solid black;
      margin: 12px 0;
      display: flex;
      padding: 16px 29px 16px 8px;
      .image {
        width: 34vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        .box {
          width: 100%;
          height: auto;
        }
      }
      .size {
        font-size: 24px;
        font-weight: bold;
      }
      .price {
        font-size: 35px;
        font-weight: bold;
      }
      .freq {
        font-size: 18px;
        margin-bottom: 16px;
      }
      .detail1, .detail2 {
        font-size: 12px;
      }
    }
  }
  .boxes-points {
    font-size: 18px;
    margin-bottom: 128px;
    ul {
      margin: 0;
    }
  }
  .footer {
    margin: 0 16px 110px 16px;
    font-size: 18px;
    .powered-by {
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .powered-by-lists-row {
      margin-bottom: 48px;
      .shg {
        margin-right: 24px;
      }
      .fic {
        width: 100%;
        height: auto;
      }
    }
    .about-us {
      margin-bottom: 16px;
    }
    .tnc {
      margin-bottom: 36px;
    }
    .contact-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 120px);
      .number {
        font-size: 35px;
        font-weight: bold;
      }
      .ig-icon {
        padding-top: 4px;
        width: 50px;
        height: auto;
      }
    }
  }
  .floating {
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 20px;
    display: inline-block;
    width: 120px;
    img {
      width: 100%; /* Adjust as needed */
      height: auto; /* Maintain aspect ratio */
      mix-blend-mode: multiply;
    }
  }
}