.whatsapp-button {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 100px;
  display: inline-block;
  background: white;
  background-color: transparent; 
}

.whatsapp-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
