.LogisticsLandingFooter {
  max-width: 960px;
  margin: 0 auto 74px;
  padding: 20px;
  .powered-by {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .powered-by-lists-row {
    margin-bottom: 48px;  
    img {
      margin-right: 24px;
    }
  }
  
  .branding p {
    margin: 5px 0;
  }
  
  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .left {
      img {
        width: auto;
        height: 65px;
      }
    }
    .right {
      display: flex;
      align-items: start;
      .hotline {
        margin-right: 36px;
      }
      .hotline-label {
        font-size: 18px;;
      }
      .hotline-number {
        font-size: 35px;
        font-weight: bold;;
      }
    }
  }
}

@media (max-width: 768px) {
  .LogisticsLandingFooter {
    flex-direction: column;
    text-align: center;
  }
}