/* src/components/BannerSlider.css */
.BannerSlider {
  .slide {
    display: inline-flex;
    width: 100%;
  }
  .banner-bg {
    width: 50%;
    height: auto;
    object-fit: cover;
  }

  .banner-content {
    width: 50%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-content-inner {
      padding: 70px;
    }
    .yellow-logo {
      width: 100%;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .banner-content p {
    margin-top: 8px;
  }

  .banner-content button {
    background: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .banner-bg {
      height: 300px;
    }
    .banner-content h1 {
      font-size: 1.5rem;
    }
    .banner-content h2 {
      font-size: 1.2rem;
    }
    .banner-content p {
      font-size: 0.9rem;
    }
  }
}