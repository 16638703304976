.LogisticsLanding {
  font-family: charter, serif;
  color: black;
  text-align: left;
  max-width: 1440px;
  margin: auto;
  button {
    font-family: charter, serif;
    border-radius: 0;
  }
  .top-title {
    font-size: 24px;
    font-weight: bold;
  }
  .main-title {
    font-size: 35px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
  .price {
    font-size: 24px;
    margin-top: 0;
  }
}